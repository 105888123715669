import i18n from '@/i18n/index'
import dataDictionary from '@/utils/data-dictionary.js'

export default {
  formData: [{
    name: 0,
    createDateItem: [{
      span: 4,
      prop: 'online',
      value: true,
      name: 'switch',
      label: i18n.t('form.product.online'),
      Object: {}
    }, {
      span: 4,
      prop: 'searchable',
      value: true,
      name: 'switch',
      label: i18n.t('form.product.searchable'),
      Object: {}
    }]
  }, {
    title: i18n.t('form.store.generalInformation'),
    name: 1,
    createDateItem: [{
      span: 9,
      prop: 'name',
      name: 'input',
      label: i18n.t('form.product.cnName'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.product.cnName') }) }
        ]
      }
    }, {
      span: 9,
      prop: 'name_en',
      name: 'input',
      label: i18n.t('form.product.enName'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        rules: [
          { required: false, message: i18n.t('text.require', { name: i18n.t('form.product.enName') }) }
        ]
      }
    }, {
      span: 9,
      prop: 'shop_id',
      name: 'select',
      options: [],
      label: i18n.t('form.common.shop'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        nextChange: true,
        nextChangeProp: 'shop_id',
        key: 'id',
        value: 'id',
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.common.shop') }) }
        ]
      }
    }, {
      span: 9,
      prop: 'item_type',
      name: 'select',
      options: [],
      label: i18n.t('form.product.shopType'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        disabled: true,
        nextChange: true,
        nextChangeProp: 'item_type',
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.product.shopType') }) }
        ]
      }
    }, {
      span: 9,
      prop: 'site_id',
      name: 'select',
      options: [],
      label: i18n.t('form.attractions.site'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        nextChange: true,
        nextChangeProp: 'site_id',
        visibleChange: [
          { item_type: ['pack_info', 'ticket_package', 'package', 'single_ticket', 'bundle_ticket', 'multi_park_ticket'] }
        ],
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.attractions.site') }) }
        ]
      }
    }, {
      span: 9,
      prop: 'itemable_id',
      name: 'select',
      options: [],
      label: '门票',
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        visibleChange: [
          { item_type: ['pack_info', 'multi_park_ticket', 'single_ticket', 'bundle_ticket', dataDictionary['003']['005'].code, dataDictionary['003']['006'].code, dataDictionary['003']['007'].code, dataDictionary['003']['008'].code, dataDictionary['003']['009'].code] }
        ],
        filterable: true
      }
    }, {
      span: 9,
      prop: 'category_ids',
      name: 'select',
      options: [],
      value: [],
      label: i18n.t('form.product.shopCategories'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.product.shopCategories') }) }
        ],
        filterable: true,
        multiple: true
      }
    }, {
      span: 9,
      prop: 'shop_sub_category_ids',
      name: 'select',
      options: [],
      value: [],
      label: i18n.t('form.product.shopSubCategories'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        rules: [
          { required: false, message: i18n.t('text.require', { name: i18n.t('form.product.shopSubCategories') }) }
        ],
        filterable: true,
        multiple: true
      }
    }, {
      span: 9,
      prop: 'wholesale_type',
      name: 'select',
      options: [
        {
          value: 'retail',
          name: i18n.t('form.product.retail')
        },
        {
          value: 'wholesale',
          name: i18n.t('form.product.wholesale')
        },
        {
          value: 'retail_and_wholesale',
          name: i18n.t('form.product.retailAndwholesale')
        }
      ],
      label: i18n.t('form.product.supportWholesale'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        client: 'combine',
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.product.supportWholesale') }) }
        ],
        filterable: true
      }
    }, {
      span: 24,
      prop: 'images_attributes',
      name: 'image',
      label: i18n.t('form.common.image'),
      placeholder: i18n.t('form.common.image'),
      Object: {
        delete: '_destroy',
        limit: 99,
        rules: [
          { required: false, message: i18n.t('text.require', { name: i18n.t('form.common.image') }) }
        ],
        promptText: `${i18n.t('message.iamgeNote')}${i18n.t('form.product.recommendedSize')} 750 x 741`
      }
    }, {
      span: 9,
      prop: 'supplier_id',
      name: 'select',
      options: [],
      label: i18n.t('form.product.supplier'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        key: 'id',
        value: 'id',
        filterable: true
      }
    }, {
      span: 9,
      prop: 'online_time',
      name: 'date',
      type: 'datetime',
      options: [],
      label: i18n.t('form.product.regularSale'),
      placeholder: i18n.t('placeholder.selectTime'),
      Object: {
        format: 'yyyy-MM-dd HH:mm:ss',
        'value-format': 'yyyy-MM-dd HH:mm:ss',
        filterable: true
      }
    }, {
      span: 9,
      prop: 'maximum_limit_per_user',
      name: 'input',
      type: 'number',
      label: i18n.t('form.product.maximumLimitPerUser'),
      placeholder: i18n.t('placeholder.purchaseCeiling'),
      Object: {
        rules: [
          { pattern: new RegExp(/^[0-9]\d*$/, 'g'), message: i18n.t('message.nonnegativePositiveInter', { name: i18n.t('form.product.maximumLimitPerUser') }), trigger: 'blur' }
        ]
      }
    }, {
      span: 9,
      prop: 'support_seven_days_return',
      name: 'switch',
      value: false,
      label: i18n.t('form.product.sevenDaysReturn'),
      Object: {}
    }, {
      span: 9,
      prop: 'after_sales_window',
      name: 'input',
      type: 'number',
      label: i18n.t('form.product.afterSalesWindow'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        visibleChange: [
          { support_seven_days_return: true }
        ],
        rules: [
          { pattern: new RegExp(/^[1-9]\d*$/, 'g'), message: i18n.t('message.positiveInter', { name: i18n.t('form.product.afterSalesWindow') }), trigger: 'blur' }
        ]
      }
    }, {
      span: 18,
      prop: 'short_description',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.product.shortDescriptionCn'),
      Object: {}
    }, {
      span: 18,
      prop: 'short_description_en',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.product.shortDescriptionEn'),
      Object: {}
    }]
  }, {
    title: i18n.t('form.product.shopStock'),
    name: 2,
    createDateItem: [{
      span: 24,
      prop: 'shop_item_stocks_attributes',
      label: i18n.t('form.product.shopStock'),
      name: 'array',
      value: [],
      Object: {
        delete: '_destroy',
        'label-width': '0'
      },
      createDateFirstItem: [{
        span: 18,
        prop: 'online',
        name: 'switch',
        value: false,
        label: i18n.t('form.product.online'),
        placeholder: i18n.t('form.product.online'),
        Object: {}
      }, {
        span: 9,
        prop: 'name',
        name: 'input',
        label: i18n.t('form.product.cnName'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          rules: [
            { required: true, message: i18n.t('text.require', { name: i18n.t('form.product.cnName') }) }
          ]
        }
      }, {
        span: 9,
        prop: 'name_en',
        name: 'input',
        label: i18n.t('form.product.enName'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          rules: [
            { required: false, message: i18n.t('text.require', { name: i18n.t('form.product.enName') }) }
          ]
        }
      }, {
        span: 9,
        prop: 'sku_id',
        name: 'input',
        label: i18n.t('SKU ID'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          rules: [
            { required: false, message: i18n.t('text.require', { name: i18n.t('SKU ID') }) }
          ]
        }
      }, {
        span: 24,
        prop: 'image_url',
        name: 'image',
        label: i18n.t('form.common.image'),
        placeholder: i18n.t('form.common.image'),
        Object: {
          delete: '_destroy',
          limit: 1,
          rules: [
            { required: false, message: i18n.t('text.require', { name: i18n.t('form.common.image') }) }
          ],
          promptText: `${i18n.t('message.iamgeNote')}${i18n.t('form.product.recommendedSize')} 176 x 200`
        }
      }, {
        span: 9,
        prop: 'host_commission_percent',
        name: 'input',
        label: i18n.t('form.product.headCommission'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          rules: [
            { pattern: new RegExp(/^[1-9]+\d*(\.\d{1,2})?$|^(0|0\.\d{1,2})$/, 'g'), message: i18n.t('message.positiveInterMaxTwoZero', { name: i18n.t('form.product.headCommission') }), trigger: 'blur' }
          ]
        }
      }, {
        span: 9,
        prop: 'direct_delivery_host_commission',
        name: 'input',
        label: i18n.t('form.product.directDeliveryHostCommission'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          rules: [
            { pattern: new RegExp(/^[1-9]+\d*(\.\d{1,2})?$|^(0|0\.\d{1,2})$/, 'g'), message: i18n.t('message.positiveInterMaxTwoZero', { name: i18n.t('form.product.directDeliveryHostCommission') }), trigger: 'blur' }
          ]
        }
      }, {
        span: 9,
        prop: 'price_f',
        name: 'input',
        type: 'number',
        label: i18n.t('form.product.sellingPrice'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          rules: [
            { required: true, message: i18n.t('text.require', { name: i18n.t('form.product.sellingPrice') }) },
            { pattern: new RegExp(/^([1-9]\d*(\.\d{1,2})?|([0](\.([0][1-9]|[1-9]\d{0,1}))))$/, 'g'), message: i18n.t('message.positiveInterMaxTwo', { name: i18n.t('form.product.sellingPrice') }), trigger: 'blur' }
          ]
        }
      }, {
        span: 9,
        prop: 'line_price_f',
        name: 'input',
        type: 'number',
        label: i18n.t('form.product.linePrice'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          rules: [
            { pattern: new RegExp(/^([1-9]\d*(\.\d{1,2})?|([0](\.([0][1-9]|[1-9]\d{0,1}))))$/, 'g'), message: i18n.t('message.positiveInterMaxTwo', { name: i18n.t('form.product.linePrice') }), trigger: 'blur' }
          ],
          validator: 'price_f',
          validatorMark: '<=',
          validatorType: 'number',
          validatorStart: i18n.t('form.product.linePrice'),
          validatorEnd: i18n.t('form.product.sellingPrice')
        }
      }, {
        span: 9,
        prop: 'saling_stock',
        name: 'input',
        type: 'number',
        label: i18n.t('form.product.slaingStock'),
        placeholder: i18n.t('placeholder.maximumInventory'),
        Object: {
          rules: [
            { required: true, message: i18n.t('text.require', { name: i18n.t('form.product.slaingStock') }) },
            { pattern: new RegExp(/^[0-9]\d*$/, 'g'), message: i18n.t('message.nonnegativePositiveInter', { name: i18n.t('form.product.slaingStock') }), trigger: 'blur' }
          ]
        }
      }, {
        span: 9,
        prop: 'warning_stock',
        name: 'input',
        type: 'number',
        label: i18n.t('form.product.warningStock'),
        placeholder: i18n.t('placeholder.alertInventory'),
        Object: {
          rules: [
            { required: true, message: i18n.t('text.require', { name: i18n.t('form.product.warningStock') }) },
            { pattern: new RegExp(/^[0-9]\d*$/, 'g'), message: i18n.t('message.nonnegativePositiveInter', { name: i18n.t('form.product.warningStock') }), trigger: 'blur' }
          ],
          validator: 'saling_stock',
          validatorType: 'number',
          validatorStart: i18n.t('form.product.warningStock'),
          validatorEnd: i18n.t('form.product.slaingStock')
        }
      }, {
        span: 9,
        prop: 'inventory_quantity',
        name: 'input',
        type: 'number',
        label: i18n.t('form.product.lockingStock'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          client: 'combine',
          rules: [
            { required: false, message: i18n.t('text.require', { name: i18n.t('form.product.lockingStock') }) }
          ]
        }
      }, {
        span: 9,
        prop: 'zone',
        name: 'input',
        label: i18n.t('form.product.zone'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          client: 'combine',
          rules: [
            { required: false, message: i18n.t('text.require', { name: i18n.t('form.product.zone') }) }
          ]
        }
      }, {
        span: 9,
        prop: 'section',
        name: 'input',
        label: i18n.t('form.product.section'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          client: 'combine',
          rules: [
            { required: false, message: i18n.t('text.require', { name: i18n.t('form.product.section') }) }
          ]
        }
      }, {
        span: 9,
        prop: 'bin',
        name: 'input',
        label: i18n.t('form.product.bin'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          client: 'combine',
          rules: [
            { required: false, message: i18n.t('text.require', { name: i18n.t('form.product.bin') }) }
          ]
        }
      }, {
        span: 9,
        prop: 'shop_item_stock_tier_prices_attributes',
        name: 'component',
        label: i18n.t('form.product.wholesalePrice'),
        placeholder: i18n.t('form.product.wholesalePrice'),
        Object: {
          client: 'combine',
          visibleChange: [
            { wholesale_type: ['wholesale', 'retail_and_wholesale'] }
          ],
          rules: [
            { required: true, message: i18n.t('text.firstRequired'), trigger: ['blur'] }
          ],
          name: 'PriceTier'
        }
      }]
    }]
  }, {
    title: i18n.t('form.product.preferences'),
    name: 3,
    createDateItem: [{
      span: 24,
      prop: 'multiple_preferences',
      name: 'switch',
      value: false,
      label: i18n.t('form.product.allowMultiple'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        rules: [
          { required: false, message: i18n.t('text.require', { name: i18n.t('form.product.allowMultiple') }) }
        ]
      }
    }, {
      span: 24,
      prop: 'shop_item_preferences_attributes',
      label: i18n.t('form.product.preferences'),
      name: 'array',
      value: [],
      Object: {
        delete: '_destroy',
        'label-width': '0'
      },
      createDateFirstItem: [{
        span: 9,
        prop: 'name',
        name: 'input',
        label: i18n.t('form.product.title'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          rules: [
            { required: true, message: i18n.t('text.require', { name: i18n.t('form.product.title') }) }
          ]
        }
      }, {
        span: 9,
        prop: 'display_order',
        name: 'input',
        label: i18n.t('form.product.displayOrder'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          rules: [
            { required: false, message: i18n.t('text.require', { name: i18n.t('form.product.displayOrder') }) }
          ]
        }
      }, {
        span: 9,
        prop: 'min_count',
        name: 'input',
        type: 'number',
        label: i18n.t('form.product.minCount'),
        placeholder: i18n.t('placeholder.likeMinCount'),
        Object: {
          rules: [
            { required: true, message: i18n.t('text.require', { name: i18n.t('form.product.minCount') }) },
            { pattern: new RegExp(/^[0-9]\d*$/, 'g'), message: i18n.t('message.nonnegativePositiveInter', { name: i18n.t('form.product.minCount') }), trigger: 'blur' }
          ]
        }
      }, {
        span: 9,
        prop: 'max_count',
        name: 'input',
        type: 'number',
        label: i18n.t('form.product.maxCount'),
        placeholder: i18n.t('placeholder.likeMaxCount'),
        Object: {
          rules: [
            { required: false, message: i18n.t('text.require', { name: i18n.t('form.product.maxCount') }) }
          ],
          validator: 'min_count',
          validatorMark: '<',
          validatorType: 'number',
          validatorStart: i18n.t('form.product.maxCount'),
          validatorEnd: i18n.t('form.product.minCount')
        }
      }, {
        span: 24,
        prop: 'preference_options_attributes',
        label: i18n.t('form.product.preferenceOptions'),
        name: 'array',
        value: [],
        Object: {
          delete: '_destroy',
          'label-width': '0'
        },
        createDateFirstItem: [{
          span: 9,
          prop: 'name',
          name: 'input',
          label: i18n.t('form.product.name'),
          placeholder: i18n.t('placeholder.defaultInput'),
          Object: {
            rules: [
              { required: true, message: i18n.t('text.require', { name: i18n.t('form.product.name') }) }
            ]
          }
        }, {
          span: 9,
          prop: 'price',
          name: 'input',
          label: i18n.t('form.product.priceUnit'),
          placeholder: i18n.t('placeholder.defaultInput'),
          Object: {
            rules: [
              { required: true, message: i18n.t('text.require', { name: i18n.t('form.product.priceUnit') }) },
              { pattern: new RegExp(/^[1-9]+\d*(\.\d{1,2})?$|^(0|0\.\d{1,2})$/, 'g'), message: i18n.t('message.positiveInterMaxTwoZero', { name: i18n.t('form.product.priceUnit') }), trigger: 'blur' }
            ]
          }
        }]
      }]
    }]
  }, {
    title: i18n.t('form.product.productDetail'),
    name: 4,
    createDateItem: [{
      span: 24,
      prop: 'description',
      name: 'quill-editor',
      label: i18n.t('form.product.descriptionCn'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        rules: [
          { required: false, message: i18n.t('text.require', { name: i18n.t('form.product.descriptionCn') }) }
        ]
      }
    }, {
      span: 24,
      prop: 'description_en',
      name: 'quill-editor',
      label: i18n.t('form.product.descriptionEn'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        rules: [
          { required: false, message: i18n.t('text.require', { name: i18n.t('form.product.descriptionEn') }) }
        ]
      }
    }, {
      span: 24,
      prop: 'notices_attributes',
      label: i18n.t('form.product.buyNotices'),
      name: 'array',
      value: [],
      Object: {
        delete: '_destroy',
        'label-width': '0'
      },
      createDateFirstItem: [{
        span: 9,
        prop: 'title',
        name: 'input',
        label: i18n.t('form.product.titleCn'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          rules: [
            { required: false, message: i18n.t('text.require', { name: i18n.t('form.product.titleCn') }) }
          ]
        }
      }, {
        span: 9,
        prop: 'title_en',
        name: 'input',
        label: i18n.t('form.product.titleEn'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          rules: [
            { required: false, message: i18n.t('text.require', { name: i18n.t('form.product.titleEn') }) }
          ]
        }
      }, {
        span: 9,
        prop: 'display_order',
        name: 'input',
        label: i18n.t('form.product.displayOrder'),
        placeholder: i18n.t('placeholder.displayOrder'),
        type: 'number',
        Object: {
          rules: [
            { required: false, message: i18n.t('text.require', { name: i18n.t('form.product.displayOrder') }) }
          ]
        }
      }, {
        span: 9,
        prop: 'detail',
        name: 'input',
        label: i18n.t('form.product.contentCn'),
        type: 'textarea',
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          rules: [
            { required: false, message: i18n.t('text.require', { name: i18n.t('form.product.contentCn') }) }
          ]
        }
      }, {
        span: 9,
        prop: 'detail_en',
        name: 'input',
        label: i18n.t('form.product.contentEn'),
        type: 'textarea',
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          rules: [
            { required: false, message: i18n.t('text.require', { name: i18n.t('form.product.contentEn') }) }
          ]
        }
      }]
    }]
  }, {
    title: i18n.t('form.product.productDisplay'),
    name: 5,
    createDateItem: [{
      span: 9,
      prop: 'recommend',
      name: 'switch',
      value: false,
      label: i18n.t('form.product.recommend'),
      Object: {}
    }, {
      span: 9,
      prop: 'rank',
      name: 'input',
      type: 'number',
      label: i18n.t('form.product.ranking'),
      placeholder: i18n.t('placeholder.showRanking'),
      Object: {
        rules: [
          { required: false, message: i18n.t('text.require', { name: i18n.t('form.product.ranking') }) }
        ]
      }
    }, {
      span: 9,
      prop: 'init_sale',
      name: 'input',
      type: 'number',
      label: i18n.t('form.product.initSale'),
      placeholder: i18n.t('placeholder.initialSales'),
      Object: {
        rules: [
          { required: false, message: i18n.t('text.require', { name: i18n.t('form.product.initSale') }) },
          { pattern: new RegExp(/^(?:[1-9]\d*|0)$/, 'g'), message: i18n.t('message.nonnegativePositiveInter', { name: i18n.t('form.product.initSale') }), trigger: 'blur' }
        ]
      }
    }]
  }, {
    title: i18n.t('form.product.label'),
    name: 6,
    createDateItem: [{
      span: 4,
      prop: 'refrigerated',
      name: 'switch',
      value: false,
      label: i18n.t('text.refrigeratedProduct'),
      Object: {}
    }, {
      span: 4,
      prop: 'frozen_product',
      name: 'switch',
      value: false,
      label: i18n.t('text.frozenProduct'),
      Object: {}
    }, {
      span: 8,
      prop: 'non_halal',
      name: 'switch',
      value: false,
      label: i18n.t('Non halal'),
      Object: {}
    }, {
      span: 9,
      prop: 'global_labels',
      name: 'select',
      options: [],
      value: [],
      label: i18n.t('form.product.globalLabels'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        value: 'name',
        rules: [
          { required: false, message: i18n.t('text.require', { name: i18n.t('form.product.globalLabels') }) }
        ],
        multiple: true,
        filterable: true
      }
    }, {
      span: 9,
      prop: 'invisible_for_user_groups',
      name: 'select',
      options: [],
      value: [],
      label: i18n.t('form.product.invisibleFor'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        client: 'combine',
        rules: [
          { required: false, message: i18n.t('text.require', { name: i18n.t('form.product.invisibleFor') }) }
        ],
        multiple: true,
        filterable: true
      }
    }, {
      span: 24,
      prop: 'special_labels_attributes',
      label: i18n.t('form.product.featuredTags'),
      name: 'array',
      value: [],
      Object: {
        delete: '_destroy',
        'label-width': '0'
      },
      createDateFirstItem: [{
        span: 9,
        prop: 'name',
        name: 'input',
        label: i18n.t('form.product.cnName'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          rules: [
            { required: false, message: i18n.t('text.require', { name: i18n.t('form.product.cnName') }) }
          ]
        }
      }, {
        span: 9,
        prop: 'name_en',
        name: 'input',
        label: i18n.t('form.product.enName'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          rules: [
            { required: false, message: i18n.t('text.require', { name: i18n.t('form.product.enName') }) }
          ]
        }
      }]
    }, {
      span: 24,
      prop: 'invisible_labels_attributes',
      label: i18n.t('form.product.invisibleLabel'),
      name: 'array',
      value: [],
      Object: {
        delete: '_destroy',
        'label-width': '0'
      },
      createDateFirstItem: [{
        span: 9,
        prop: 'name',
        name: 'input',
        label: i18n.t('form.product.cnName'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          rules: [
            { required: false, message: i18n.t('text.require', { name: i18n.t('form.product.cnName') }) }
          ]
        }
      }, {
        span: 9,
        prop: 'name_en',
        name: 'input',
        label: i18n.t('form.product.enName'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          rules: [
            { required: false, message: i18n.t('text.require', { name: i18n.t('form.product.enName') }) }
          ]
        }
      }]
    }]
  }]
}
