<template>
  <div>
    <alter-form labelWidth="160px" :watchImageVisible="true" :collapseLength="collapseLength" :modifyData="modifyData"
      @formSubmit="formSubmit" :formData="formData" @handleChange="handleChange"></alter-form>
  </div>
</template>

<script>
// 组件
import alterForm from '@/components/alterForm'
// 接口
import * as suppliersApi from '@/services/suppliers.js'
import * as shopItemsApi from '@/services/shop-items.js'
import * as shopsApi from '@/services/shops.js'
import * as shopCategoriesApi from '@/services/shop-categories.js'
import * as shopSubCategoriesApi from '@/services/shop-sub-categories.js'
import * as shopItemLabelsApi from '@/services/shop-item-labels.js'
import { getShopItemSites, getShopItemItemables, getWholesalePricingTiers, getOutletSkusSelect } from '@/services/product.js'
import { getUserGroupSimpleList } from '@/services/members.js'
// 其他
import dataDictionary from '@/utils/data-dictionary.js'
import loading from '@/utils/loading.json'
import jsonData from './jsonData'
import Utils from '@/utils/Utils.js'
import _ from 'lodash'

export default {
  name: 'goodAdd',
  components: {
    alterForm
  },
  data() {
    return {
      modifyData: {},
      collapseLength: [],
      formData: _.cloneDeep(jsonData.formData),
      product_f: {},
      codes: ['pack_info', 'multi_park_ticket', 'single_ticket', 'bundle_ticket', dataDictionary['003']['005'].code, dataDictionary['003']['006'].code, dataDictionary['003']['007'].code, dataDictionary['003']['008'].code, dataDictionary['003']['009'].code]
    }
  },
  watch: {},
  created() {
    // Utils.isLoginCms(this)
    this.init()
  },
  mounted() { },
  methods: {
    async init() {
      this.collapseLength = [...new Array(this.formData.length).keys()]
      this.$store.state.app.loading = this.$loading(loading)
      const shopsData = await shopsApi.getShopsSimpleList()
      const suppliersData = await suppliersApi.getSimpleList()
      const globalLabels = await shopItemLabelsApi.getShopItemSimpleList()
      if (this.app_name === 'combine') {
        const userGroups = await getUserGroupSimpleList()
        // const wholesalePricingTiers = await getWholesalePricingTiers()
        this.formData[7].createDateItem[4].options = userGroups.data.user_groups
        // this.formData[2].createDateItem[0].createDateFirstItem[13] = wholesalePricingTiers.data.wholesale_picing_tiers
      }
      this.formData[1].createDateItem[2].options = shopsData.data.shops
      this.formData[1].createDateItem[10].options = suppliersData.data.suppliers
      this.formData[6].createDateItem[3].options = globalLabels.data.global_labels
      if (this.$route.params.id) {
        const data = await shopItemsApi.getIdPreview(this.$route.params.id)
        if (data.status === 200 && data.data) {
          const product_f = data.data.shop_item
          const product = Object.assign({}, product_f)
          this.product_f = product_f
          this.shopChange(product.shop_id)
          this.getCouponOrSite(product.item_type, product)
          product.category_ids = product.shop_categories.map(item => item.id)
          product.shop_sub_category_ids = product.shop_sub_categories.map(item => item.id)
          product.global_labels = product.global_labels.map(label => {
            return label.name
          })
          product.online_time = product.online_time ? new Date(product.online_time) : product.online_time
          this.formData[1].createDateItem[2].Object.disabled = true
          this.formData[1].createDateItem[3].Object.disabled = true
          this.modifyData = { ...product }
        }
      }
      console.log(this.modifyData)
      this.$store.state.app.loading.close()
    },
    async handleChange(data, nextChangeProp, item, createDate) {
      if (nextChangeProp === 'shop_id') {
        this.formData[1].createDateItem[3].Object.disabled = false
        this.$set(this.modifyData, 'category_ids', [])
        this.$set(this.modifyData, 'shop_sub_category_ids', [])
        this.shopChange(data)
      }
      if (nextChangeProp === 'item_type') {
        // 景点、优惠券
        this.$set(this.modifyData, 'maximum_limit_per_user', null)
        this.$set(this.modifyData, 'special_labels_attributes', [])
        this.$set(this.modifyData, 'invisible_labels_attributes', [])
        this.$set(this.modifyData, 'images_attributes', [])
        this.$set(this.modifyData, 'notices_attributes', [])
        this.$set(this.modifyData, 'shop_item_stocks_attributes', [])
        this.$set(this.modifyData, 'shop_item_preferences_attributes', [])
        this.$set(this.modifyData, 'itemable_id', '')
        this.$set(this.modifyData, 'site_id', '')
        // this.$set(this.modifyData, 'name', '')
        // this.$set(this.modifyData, 'name_en', '')
        this.getCouponOrSite(data, createDate)
      }
      if (nextChangeProp === 'site_id') {
        // 门票
        this.getTiket(data, createDate)
      }
    },

    async shopChange(data) {
      const params = {
        shop_id: data
      }
      this.formData[1].createDateItem[3].Object.loading = true
      const itemTypes = await shopItemsApi.getItemTypeListByShop(params)
      this.formData[1].createDateItem[3].options = itemTypes.data.item_types
      this.formData[1].createDateItem[3].Object.loading = false

      this.formData[1].createDateItem[6].Object.loading = true
      this.formData[1].createDateItem[7].Object.loading = true
      const shopCategories = await shopCategoriesApi.getSimpleListByShopId(params)
      const shopSubCategories = await shopSubCategoriesApi.getSimpleListByShopId(params)
      this.formData[1].createDateItem[6].options = shopCategories.data.shop_categories
      this.formData[1].createDateItem[7].options = shopSubCategories.data.shop_sub_categories
      this.formData[1].createDateItem[6].Object.loading = false
      this.formData[1].createDateItem[7].Object.loading = false
    },
    async getCouponOrSite(data, createDate) {
      if (this.codes.includes(data)) {
        this.formData[1].createDateItem[5].label = this.getName(data)
      }

      // 景点
      if (data === 'pack_info' || data === 'ticket_package' || data === 'package' || data === 'single_ticket' || data === 'bundle_ticket' || data === 'multi_park_ticket') {
        this.formData[1].createDateItem[4].Object.loading = true
        this.formData[1].createDateItem[5].Object.disabled = true
        const sites = await getShopItemSites()
        this.formData[1].createDateItem[4].options = sites.data
        this.formData[1].createDateItem[4].Object.loading = false
      }

      // 优惠券
      if (data === dataDictionary['003']['005'].code || data === dataDictionary['003']['006'].code || data === dataDictionary['003']['007'].code || data === dataDictionary['003']['008'].code || data === dataDictionary['003']['009'].code) {
        const params = {
          shop_id: createDate.shop_id,
          item_type: createDate.item_type
        }
        this.formData[1].createDateItem[5].Object.loading = true
        const coupons = await shopItemsApi.getShopItemsItemables(params)
        this.formData[1].createDateItem[5].options = coupons.data.item_type_list
        this.formData[1].createDateItem[5].Object.loading = false
      }
    },

    //门票
    async getTiket(data, createDate) {
      this.formData[1].createDateItem[5].Object.loading = true
      const packInfo = await getShopItemItemables(createDate.item_type, createDate.shop_id, createDate.site_id)
      this.formData[1].createDateItem[5].options = packInfo.data.item_type_list
      this.formData[1].createDateItem[5].Object.disabled = false
      this.formData[1].createDateItem[5].Object.loading = false
    },

    getName(item_type) {
      if (item_type === dataDictionary['003']['002'].code) {
        return dataDictionary['003']['002'].name
      } else if (item_type === dataDictionary['003']['004'].code) {
        return dataDictionary['003']['004'].name
      } else if (item_type === dataDictionary['003']['005'].code) {
        return dataDictionary['003']['005'].name
      } else if (item_type === dataDictionary['003']['006'].code) {
        return dataDictionary['003']['006'].name
      } else if (item_type === dataDictionary['003']['007'].code) {
        return dataDictionary['003']['007'].name
      } else if (item_type === dataDictionary['003']['008'].code) {
        return dataDictionary['003']['008'].name
      } else if (item_type === dataDictionary['003']['009'].code) {
        return dataDictionary['003']['009'].name
      } else if (item_type === 'multi_park_ticket') {
        return this.$t('menu.multiParkTickets')
      } else if (item_type === 'bundle_ticket') {
        return this.$t('menu.tPackage')
      } else if (item_type === 'single_ticket') {
        return this.$t('menu.ticketPackage')
      }
    },
    formSubmit(data) {
      //如果重复点击直接return（下方提交成功200后置repeatedClicks为true）避免重复提交
      if( this.repeatedClicks ) return;
      this.$store.state.app.loading = this.$loading(loading)
      const obj = JSON.parse(JSON.stringify(data))
      // obj.image_id = obj.image_id && obj.image_id.length ? obj.image_id[0].id : null
      // this.$store.state.app.loading.close()
      obj.category_ids = obj.category_ids.join(',')
      obj.shop_sub_category_ids = obj.shop_sub_category_ids.join(',')
      obj.global_labels = obj.global_labels.join(',')
      if (['package', 'ticket_package', 'bundle_ticket', 'single_ticket', 'multi_park_ticket'].includes(obj.item_type)) {
        obj.itemable_type = 'TicketPackage'
      }
      if (['ticket'].includes(obj.item_type)) {
        obj.itemable_type = 'PackInfo'
      }
      let api = null
      const arr = []
      if (this.$route.params.id) {
        api = shopItemsApi.putShopItems
        arr.push(this.$route.params.id)
        obj.special_labels_attributes = this.formatLabelDestory('special_labels_attributes', obj)
        obj.invisible_labels_attributes = this.formatLabelDestory('invisible_labels_attributes', obj)
        obj.shop_item_preferences_attributes = this.formatLabelDestory('shop_item_preferences_attributes', obj, 'preference_options_attributes')
        obj.shop_item_stocks_attributes = this.formatLabelDestory('shop_item_stocks_attributes', obj)
        obj.shop_item_stocks_attributes.forEach(item => {
          if (item.image_url != null) {
            item.should_remove_image = false
          } else {
            item.should_remove_image = true
          }
          item.remote_image_url = item.image_url
          delete item.image_url
        })
        obj.notices_attributes = this.formatLabelDestory('notices_attributes', obj)
      } else {
        api = shopItemsApi.postShopItems
      }
      console.log(obj)
      const params = {
        shop_item: obj
      }
      arr.push(params)
      api(...arr).then(res => {
        if (res.status === 200) {
          this.repeatedClicks = true
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess'),
            duration: 1000,
            onClose: () => {
              this.$router.go(-1)
            }
          })
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },
    formatLabelDestory(key, data, secondKey) {
      if (this.product_f[key] && this.product_f[key].length === 0) {
        return data[key]
      }

      let delArr = []
      let dataArr = JSON.parse(JSON.stringify(data[key]))

      this.product_f[key].map ((label) => {
        if (dataArr && dataArr.length > 0) {
          const index = dataArr.findIndex((item) => label.id === item.id);
          if (index <= -1) {
            label._destroy = 1
            delArr.push(label)
          } else {
            //比较子项有没有删除
            if(secondKey != null && secondKey != 'undefined') {
              dataArr[index][secondKey] = this.secondFormatLabelDestory(secondKey, label, dataArr[index])
            }
          }
        } else {
          label._destroy = 1
          delArr.push(label)
        }
      })
      return [...delArr,...dataArr]
    },
    secondFormatLabelDestory(key, oldData, data) {
      if (oldData[key] && oldData.length === 0) {
        return data
      }

      let delArr = []
      let dataArr = JSON.parse(JSON.stringify(data[key]))

      oldData[key].map ((label) => {
        if (dataArr && dataArr.length > 0) {
          const index = dataArr.findIndex((item) => label.id === item.id);
          if (index <= -1) {
            label._destroy = 1
            delArr.push(label)
          }
        } else {
          label._destroy = 1
          delArr.push(label)
        }
      })
      return [...delArr,...dataArr]
    }
  }
}
</script>

<style lang="scss" scope>
</style>
